.accordion-card {
  margin-bottom: 4px;
  &.MuiAccordion-root {
    border-radius: 3px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
      0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
    &::before {
      background: none;
    }
    &.Mui-expanded {
      margin: 0px 0px 4px;
    }
  }
  .MuiAccordionSummary-root {
    padding: 0px;
    height: 100%;
    min-height: 48px;
    &.Mui-expanded {
      height: 100%;
      min-height: 48px;
    }
    .MuiAccordionSummary-content {
      margin: 10px;
      .core-list {
        padding-bottom: 0px;
        width: 100%;
        .MuiListItem-root {
          box-shadow: unset;
          background: unset;
          margin: unset;
        }
      }
    }
    .MuiButtonBase-root {
      padding: 0px;
      margin-right: 14px;
      // color: #212121;
    }
  }
  .MuiAccordionDetails-root {
    padding: 10px;
    flex-wrap: wrap;
    border-top: 1px solid #e6e6e6;
  }
}