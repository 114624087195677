.quantity {
	.MuiOutlinedInput-input {
		// padding-left: 23px;
		text-align: right;
	}
	.MuiOutlinedInput-inputMarginDense {
		padding-top: 6px;
		padding-bottom: 6px;
	}
}
