.outlined-disabled {
	.MuiInputBase-root.Mui-disabled {
		color: #212121;
		background-color: #f5f5f5;
	}
	.MuiFormLabel-root.Mui-disabled {
		color: #212121;
	}

	.MuiOutlinedInput-input {
		padding: 10.5px 10px;
	}
}
.outlined-input {
	.MuiInputBase-input {
		padding: 2px 2px;
		text-align: right;
		font-size: 15px;
		border: 1.5px solid #ff9800;
		border-radius: 3px;
	}
}
.input-outlined {
	display: flex;
	width: 100%;
	@extend .outlined-input;

	.input-price {
		.MuiInputBase-root {
			width: 90px;
			margin: 0px 0px 0px auto;
		}
	}
}
.disabled {
	.MuiInputBase-root.Mui-disabled {
		color: #212121;
		background-color: #f5f5f5;
	}
	.MuiInputBase-input {
		padding: 4px 8px;
		text-align: right;
		font-size: 12px;
		// border: 1.5px solid #ff9800;
		border-radius: 3px;
	}
}

.disabled-edit-input {
	.MuiInputBase-root.Mui-disabled {
		color: #212121;
		font-weight: 500;
		background-color: #f5f5f5;
	}
	.MuiInput-root {
		border-radius: 3px;
		border: 1.5px solid #f5f5f5;
		text-align: right;
	}
	.MuiOutlinedInput-adornedEnd {
		padding-right: 8px;
	}
	.MuiInputBase-input {
		padding: 4px 8px;
		text-align: right;
		font-size: 12px;
		// border: 1.5px solid #ff9800;
	}
}
.uom-input {
	display: flex;
	width: 100%;
	@extend .outlined-input;
}

.qty-input {
	display: flex;
	width: 100%;
	@extend .outlined-input;
}
.price-input {
	display: flex;
	width: 100%;
	min-width: 50%;
	@extend .outlined-input;
}

.disabled-input {
	display: flex;
	width: 100%;
	min-width: 50%;
	@extend .disabled;
}

.disabled-edit {
	display: flex;
	width: 100%;
	cursor: pointer;
	min-width: 50%;
	@extend .disabled-edit-input;
}

.helper {
	.MuiFormHelperText-root {
		white-space: break-spaces;
		white-space: inherit;
		margin-top: 0px;
		font-size: 0.6rem;
	}
	.MuiFormHelperText-contained {
		margin-left: 0;
		margin-right: 0;
	}
}

.p-l-10 {
	padding-left: 10px;
}

.table-list {
	.MuiListItem-root {
		box-shadow: none;
		margin-bottom: 0px;
		padding: 0px;
		max-height: 40px;
	}
}

.table-form {
	.MuiCardContent-root {
		display: block;
    margin-top: 8px;
	}
}

.table {
  .MuiListItem-root.MuiListItem-gutters {
    margin-bottom: 0px;
		padding: 0px;
		max-height: 40px;
  }
}
