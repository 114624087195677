.dropdown-subheader {
	background: #fff;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
		0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
	// &.MuiList-root {
	//   padding: 0px;
	// }
	position: fixed !important;
	width: 100%;
	z-index: 200;
	top: 123px;

	&.MuiList-root {
		padding: 0px;
	}
	&.full-width {
		left: 24px;
		width: calc(100% - 48px);
	}
	.MuiListItem-root {
		padding-left: 12px;
	}
	.MuiListItemText-root {
		margin: 0px;
		display: flex;
		padding: 4px 0px;
	}
	.MuiIconButton-edgeEnd {
		padding: 8px;
		color: #212121;
		margin-right: -8px;
	}
	.MuiInputBase-root {
		width: 100%;
		.MuiInputBase-input {
			padding: 0px;
			font-size: 14px;
			font-weight: 500;
		}
	}

	@media screen and (min-width: 600px) {
		width: calc(100% - 288px);
		// position: fixed !important;
		// z-index: 100;
		top: 211px;
	}
}
