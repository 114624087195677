.outlined-input {
	.MuiInputBase-input {
		padding: 6px 8px;
		text-align: right;
		font-size: 12px;
		border: 1.5px solid #ffb11f;
		border-radius: 3px;
		font-weight: bold;
	}
}

.conversion-input {
	display: flex;
	width: 100%;
	@extend .outlined-input;
}

.outlined-dropdown-input {
	.MuiInputBase-input {
		padding: 4px 8px;
		text-align: left;
		font-size: 12px;
		border: 1.5px solid #ffb11f;
		border-radius: 3px;
		font-weight: bold;
	}
}

.conversion-dropdown-input {
	display: flex;
	width: 100%;
	@extend .outlined-dropdown-input;
}

.conversion-icon {
	width: 100%;
	justify-content: center;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 6px;
}

.MuiGrid-grid-xs-1 {
	flex-grow: 0;
	max-width: 8.333333%;
	flex-basis: 5%;
}

.repeat-icon {
	font-size: 13px;
	padding-left: 3px;
	padding-right: 3px;
}
