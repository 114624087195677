.react-tel-input .form-control:focus {
  border-color: darkgrey;
  box-shadow: none;
}
.react-tel-input .form-control {
  padding: 16px 12px 8px 58px;
}

.react-tel-input .selected-flag {
  margin-top: 3px;
}

.react-tel-input .form-control.invalid-number:focus {
  box-shadow: none;
  border-bottom: '1px solid #900';
  border: 'none';
}

.react-tel-input .special-label {
  left: -5px;
  color: #878787;
  font-weight: 400;
  background-color: transparent;
	font-family: 'Poppins', sans-serif !important;
  font-size: 12px;
}

.special-label-error {
  .react-tel-input .special-label {
    color: #e53935;
  }
}

.phonefield {
  &__input{
    display: flex;
    align-items: center;
    padding: 10px;
    &-adornment{
      padding-left:10px; 
      padding-right:10px; 
    }
  }
  &__adornment {
    position: absolute;
    top: 8px;
    left: 12px;
    &-text {
      color: orange;
    }
    
  }
}