.sigCanvas {
	width: 100%;
	min-height: 300px;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #ffe5b5;
}

.sigTitleText {
	font-weight: 600;
	text-align: left;
}

.cash-radio {
	.MuiTypography-body1 {
		width: 100%;
	}
}

.bill-checkbox {
	width: 40%;
	left: 16px;
	flex-flow: row;
	flex: 1 2;
	.MuiTypography-root {
		font-size: 12px;
	}
}

.example-print {
	display: none;
}
@media print {
	.example-screen {
		display: none;
	}
	.example-print {
		display: block;
	}
}

.helper-text {
	.MuiFormHelperText-root {
		color: red !important;
	}
}

.cover-layer {
	position: fixed;
	left: 0px;
	bottom: 0px;
	z-index: -1;
	width: 100%;
	height: 100%;
	background: #f5f5f5;
}


.billSettlement-number{ 
	font-size: 14px;
	font-weight: 700;
	color: #000000;


}

.xs-billSettlement-number{
	font-size: 12px;
	// font-weight: 700;
	color: #000000;
	font-family: 'Times New Roman', Times, serif;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

}