.banner-section {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    //   display: flex;
    //   box-shadow: 5px 5px 10px #cccccc, -5px -5px 10px #f9f9f9;
  
    background: linear-gradient(320deg, #e5e8f7 0%, #eaedf4 100%);
    height: 100px;
    text-align: center;
    margin: auto;
    padding: 10px 0px;
    img {
      display: block;
      margin: 0 auto;
      width: 80px;
    }
    .button-section {
      display: flex;
      justify-content: space-between;
      padding: 8px 24px;
    }
  }
  