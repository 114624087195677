.outlet-table-label {
	max-inline-size: fit-content;
	font-weight: 800;
	// max-width: 30px;
	width: fit-content;
	padding-left: 4px;
	padding-right: 4px;
}


.fixed-tabs {
	background-color: black;
	position: fixed;
	top: 0; /* Adjust the value based on the position you need */
	width: 100%;
	z-index: 999; /* Ensures it stays on top of other content */
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow effect */
  }

  .core-tab-bill-listing {
	z-index: 100;
	@media screen and (min-width: 100px) {
	  margin-top: 40px;
	  margin-left: 3px;
	}
	padding: 0px 0px;
  
	.MuiAppBar-colorPrimary {
	  color: white;
	  background-color: white;
	  .MuiTab-wrapper {
		text-transform: capitalize;
		font-weight: 600;
	  }
	  .PrivateTabIndicator-colorSecondary {
		background-color: white;
	  }
	}
	.tab-font {
	  font-size: 0.58rem;
	}
  
	&.single-dynamicInfo {
	  margin-top: 38px;
	  @media screen and (min-width: 100px) {
		margin-top: 105px !important;
	  }
	}
  
	&.multi-dynamicInfo {
	  margin-top: 53px;
	  @media screen and (min-width: 100px) {
		margin-top: 122px !important;
	  }
	}
  }