.accord-container {
	.MuiPaper-root {
		background: #ffe5b5;
	}
	.MuiPaper-root.MuiAccordion-root {
		position: fixed;
		bottom: 52px;
		width: 100%;
	}
	.payment-item-header {
		font-size: 14px;
		font-weight: 600;
		align-self: center;
	}
	.accordion-header {
		font-size: 14px;
		font-weight: 600;
		align-self: center;
		padding-left: 16px;
		padding-bottom: 4px;
		position: relative;
		top: 10px;
	}
	.body-text {
		font-size: 12px;
		font-weight: 500;
		color: black;
		align-self: center;
	}

	.MuiAccordionDetails-root {
		padding: 0px 3px 16px;
		flex-direction: column;
	}

	.MuiAccordionSummary-expandIcon {
		position: relative;
		top: -24px;
		padding: 0px;
		right: 30px;
	}

	.right-text {
		position: relative;
		left: 42px;
	}

	.MuiAccordionSummary-root {
		padding: 0px;
		height: 85px;
		margin-top: 5px;
	}

	.MuiAccordionDetails-root {
		padding: 0px;
	}
}
