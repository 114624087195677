.item-name {
	.MuiTypography-body1 {
		padding-bottom: 4px;
	}
}

.quantity {
	.MuiOutlinedInput-input {
		text-align: right;
	}
}

// .option-item-icons {
// 	.MuiTouchRipple-root {
// 		position: inherit;
// 	}
// }
