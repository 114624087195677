.MuiGrid-item {
	padding-bottom: 4px;
}

.icon-pencil {
	display: flex;
	flex: 1 0 auto;
}

.custom-add {
	.MuiOutlinedInput-input {
		text-align: right;
		padding: 5px;
		// width: 75px;
	}
}

.custom-add-multiple {
	.MuiOutlinedInput-input {
		padding: 5px;
		width: 100%;
		right: 5px;
	}
	padding-right: 7px;
}

.checkbox-cat-add {
	.MuiFormControlLabel-root {
		width: 20px;
	}
}

.monthly-day-info {
	.MuiInputBase-inputMultiline {
		text-align: center;
	}
	.MuiInputBase-multiline.MuiInputBase-marginDense {
		padding-bottom: 0px;
	}
}

.form-visitor-price {
	.MuiFormHelperText-contained {
		text-align: right;
		margin-right: 0px;
	}
}
.form-patron-price {
	.MuiFormHelperText-contained {
		margin-left: 0px;
	}
}

.list-item-patron-diag {
	.MuiFormHelperText-contained {
		text-align: right;
		margin-right: 0px;
	}
	.MuiInputBase-fullWidth {
		justify-content: right;
	}
}
.list-item-diag {
	.MuiDialogContent-root {
		overflow-y: initial;
	}
}
