.box {
	width: 100%;
	height: 100%;
	padding: 6px;
}

.custom-wrapper-main {
	padding: 0px 10px;

	// padding: 20px 10px;
	// width: 95%;
	@media screen and (min-width: 600px) {
		margin-top: 0px;
		padding-top: 75px;
	}

	&.with-float {
		// padding-bottom: 78px;
		padding-top: 20px;

		// padding-top: 29px;
	}

	&.multiple-dynamic-Info {
		margin-top: 53px;
		@media screen and (min-width: 600px) {
			margin-top: 122px !important;
		}
	}
	&.single-dynamic-Info {
		margin-top: 38px;
		@media screen and (min-width: 600px) {
			margin-top: 105px !important;
		}
	}
}
.custom-kitchen-center {
	margin: auto;
	width: 25%;
	padding: 50px;
	margin-top: 140px;
	text-align: center;
}

.custom-wrapper {
	// padding: 0px 10px;
	// padding: 0px 1px;
	@media screen and (min-width: 600px) {
		margin-top: 0px;
	}

	&.with-float {
		padding-bottom: 78px;
	}

	&.multiple-dynamic-Info {
		margin-top: 0px;
		@media screen and (min-width: 600px) {
			box-sizing: border-box;
		}
	}
	&.single-dynamic-Info {
		margin-top: 38px;
		@media screen and (min-width: 600px) {
			margin-top: 105px !important;
		}
	}
}

.kitchen-breadcrumbs {
	position: 'absolute';
	margin: 0px 0px -35px;

	@media screen and (min-width: 600px) {
		position: 'relative';
		margin: 0px;
	}
}

.outlet-table-label {
	max-inline-size: fit-content;
	font-weight: 800;
	width: 20px;
	max-width: 20px;
	padding-left: 4px;
}

.outlet-table-typography {
	font-weight: 800;
	justify-content: center;
}

.custom-header {
	display: inline-flex;
}

.div-width {
	position: relative;
	margin-left: 7px;
	border-radius: 5px 5px 0px 0px;
	border-bottom: ridge 1px;
	// margin-top: 100px;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
		0 -3px 10px 5px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
	top: 112px;
	z-index: 10;
	padding-top: 8px;
	padding-bottom: 8px;
	background: #ffffff;
	width: initial;
}

.cust-head-right {
	float: right;
	padding-right: 10px;
}

.sec-info {
	padding-left: 20px;
}

.pending-completed {
	background-color: #cccccc;
	width: 100%;
}

.pend-comp-text {
	padding-left: 2%;
}

.custom-card-form {
	.MuiPaper-rounded {
		border-radius: 0px;
	}
	// margin-bottom: 4px;
	// margin: 0px;
	border-radius: 3px;
	.MuiPaper-root {
		// box-shadow: unset;
		box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
			0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
	}
	::-webkit-scrollbar {
		width: 0px;
	}
	.MuiCardContent-root {
		display: contents;
		flex-wrap: wrap;
		padding: 8px 12px;

		.left {
			&.MuiFormControl-root,
			&.MuiAutocomplete-root {
				width: 50%;
				padding-right: 6px;
			}
		}
		.right {
			&.MuiFormControl-root,
			&.MuiAutocomplete-root {
				width: 50%;
				padding-left: 6px;
				.MuiInputLabel-formControl {
					left: 6px;
				}
			}
		}
		&:last-child {
			padding-bottom: 0px;
		}
		.MuiFormControl-root {
			width: 100%;
			margin-bottom: 12px;
			box-sizing: border-box;
		}
	}
}

.core-list-order {
	padding-bottom: 0px;
	.MuiListItem-root {
		box-shadow: unset;
		background: none;
		padding: 0px;
		margin-bottom: 12px;
	}
}

.custom-list {
	background-color: white;
	// padding-left: 10px;
}

.kitchen-img-owned {
	// display: flex;
	// margin-top: 8px;
	align-self: start;
	// margin-bottom: 2px;
	margin-right: 10px;
	margin-left: 10px;
	border: 1px solid rgb(234, 234, 234);
	width: 65px;
	height: 65px;
	justify-items: 'center';
	box-sizing: border-box;
	border-radius: 8px;
}

.remove-padding {
	padding: 0px;
}

.custom-text-list {
	margin-top: 0px;
	margin-bottom: 0px;
	align-self: start;
}

.input {
	padding: 3px;
}

.input-custom {
	.MuiOutlinedInput-input {
		padding: 5px;
		width: 100px;
	}
	border-color: blue;
	color: blue;
	border-width: 3px;
}

.dialog-span {
	float: right;
}

.patron-type-span {
	width: 100%;
}

.patron-type-div {
	width: 80%;
	padding-top: 10px;
}

.carousel-left-align {
	.slick-track {
		margin-left: 0;
	}
}

.revert-icon {
	top: 0%;
	right: 16px;
	position: absolute;
	transform: translateY(90%);
}

.cardContent-footer {
	// position: fixed;
	border: 0px;
	// border-top: outset;
	border: ridge 1px;
	background: white;
	margin-left: 5px;
	// margin-top: -49px;
	// box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 10px 0px rgba(0, 0, 0, 0.5),
	// 	0 0px 5px 0 rgba(0, 0, 0, 0.1);
	width: initial;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
}

.cardContent-head {
	// border: 0px;

	// border-top: outset;
	// border: ridge 1px;
	background: white;
	margin-left: 5px;
	// margin-top: -49px;
	width: 98%;
	text-align: center;
	padding-top: 7px;
	padding-bottom: 7px;
	border-radius: 5px;
}
