// .img-list {
// 	.img-owned {
// 		display: flex;
// 		margin-top: 8px;
// 		margin-bottom: 2px;
// 		margin-right: 4px;
// 		border: 1px solid #eaeaea;
// 		width: 55px;
// 		height: 55px;
// 		justify-items: 'center';
// 		box-sizing: border-box;
// 		border-radius: 5px;
// 	}
// }

.img-owned {
	display: flex;
	margin-top: 8px;
	margin-bottom: 2px;
	margin-right: 10px;
	border: 1px solid rgb(234, 234, 234);
	width: 55px;
	height: 55px;
	justify-items: 'center';
	box-sizing: border-box;
	border-radius: 5px;
}

.custom-add-menu-item-option {
	.MuiOutlinedInput-input {
		text-align: right;
		padding-right: 10px;
		padding-top: 8px;
		// width: 200;
	}
}
