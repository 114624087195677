.error-no-patron {
	&.MuiFormGroup-root {
		.MuiFormControlLabel-root {
			.MuiButtonBase-root {
				.MuiIconButton-label {
					.PrivateRadioButtonIcon-root-19 {
						color: red;
					}
				}
			}
		}
	}
}
