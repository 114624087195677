//! Account Mapping

.mapping-textfield {
    .MuiOutlinedInput-input {
        padding: 4px 12px;
        // text-align: right;
        font-size: 12px;
        // border: 1.5px solid #ff9800;
        border-radius: 3px;
    }
}

.mapping-tr td.table-row-sticky.MuiTableCell-root.MuiTableCell-body {
    position: sticky;
    width: 20em;
    left: 0;
    font-size: 12px;
    -webkit-box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
    -moz-box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
    box-shadow: 7px 0px 10px -8px rgba(13, 149, 255, 0.66);
}
.mapping-tr th.MuiTableCell-root.MuiTableCell-body {
    position: absolute;
    width: 20em;
    font-size: 12px;

    -webkit-box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
    -moz-box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
    box-shadow: 7px 0px 10px -8px rgba(138, 138, 138, 0.66);
    border-bottom: none;
    // background-color: #ffffff;
}

.mapping-tr td.header-bg.MuiTableCell-root.MuiTableCell-body {
    background-color: #ff9800;
    color: white;
    font-weight: 500;
}

.mapping-table tr:nth-child(odd) {
    background-color: #faf2e8;
    z-index: 1000;
}

.mapping-table tr:nth-child(even) {
    background-color: #ffffff;
    z-index: 1000;
}

.mapping-tr th:nth-child(even) {
    background-color: #faf2e8;
}

td.MuiTableCell-root {
    border-bottom: none;
}
.mapping-table .MuiTableCell-root {
    padding: 12px;
    font-size: 12px;
}
.title-row {
    color: transparent !important;
}
.title-row-blue {
    color: transparenteff;
}

.gl-export-row td.header-bg.MuiTableCell-root.MuiTableCell-body {
    background-color: #ff9800;
    color: white;
    font-weight: 500;
    padding: 12px !important;
}

.gl-export-row {
    .MuiTableCell-root {
        padding: 10px;
    }
}

.gl-margin {
    margin-top: 50px;
    @media screen and (min-width: 600px) {
        margin-top: 115px;
    }
}

.gl-margin-detail {
	margin-top: 60px;
}
