.hidden-content {
	.card-form {
		box-shadow: none;
	}
}

.hidden-table {
	.card-form {
		padding: 0;
		box-shadow: none;
	}
	.MuiCardContent-root {
		padding: 0;
	}
}

.cover-layer {
	position: fixed;
	left: 0px;
	bottom: 0px;
	z-index: -0;
	width: 100%;
	height: 100%;
	background: #f5f5f5;
}

.custom-date-bi {
	.MuiOutlinedInput-input {
		padding-left: 10px;
		padding-top: 12px;
		padding-bottom: 12px;
		font-size: 12px;
		font-weight: 500;
	}
	.MuiOutlinedInput-adornedEnd {
		padding-right: 10px;
		margin-top: 2px;
	}
	.MuiIconButton-root {
		padding: 0px;
	}
	// .MuiInputBase-fullWidth {
	// 	width: 100%;
	// }
	.dropdown-bi {
		.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
			padding-top: 12px;
			padding-bottom: 12px;
			font-size: 12px;
			font-weight: 500;
		}
	}
}
