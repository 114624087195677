.wrapper {
	width: 600px;
}
.itemHolder {
	margin: 0;
	padding: 0;
}

.itemHolder li {
	float: left;
	margin-bottom: 10px;
}
.itemHolder li div {
	width: 200px;
}

.outlet-table {
	width: 80%;
	height: 60px;
	margin: 5px;
	max-width: 100px;
	max-height: 60px;
	display: inline-block;
	padding-right: 5px;
}

.outlet-table-ul {
	cursor: pointer;
}

.outlet-table-label {
	max-inline-size: fit-content;
	font-weight: 800;
	width: fit-content;
	// max-width: 30px;
	padding-left: 4px;
	padding-right: 4px;
}

.outlet-table-typography {
	font-weight: 800;
	justify-content: center;
}

.outlet-core-list {
	padding: 0;
}

.custom-date-reservation {
	.MuiOutlinedInput-input {
		padding-left: 10px;
		padding-top: 12px;
		padding-bottom: 12px;
		font-size: 12px;
		font-weight: 500;
	}
	.MuiOutlinedInput-adornedEnd {
		padding-right: 10px;
	}
	.MuiIconButton-root {
		padding: 0px;
	}
}
