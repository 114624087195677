.sales-amount {
	.MuiOutlinedInput-multiline {
		padding: 10px;
	}
}

.user-dropdown {
	.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
		padding: 9.5px;
	}
}
