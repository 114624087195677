.mgmt-search-header {
	padding: 8px;
	// background-color: #bfd3fe;
	.MuiSelect-outlined.MuiSelect-outlined {
		padding: 8px;
		background-color: #fff;
		font-size: 13px;
		font-weight: 500;
	}
	.header {
		padding: 10px 0px;
		background-color: #fff;
	}
}

.container {
	position: relative;
	text-align: center;
	color: white;
}

.img-sold-out-text {
	color: red;
	font-weight: 900;
	position: absolute;
	top: 55%;
	left: 40%;
	transform: translate(-50%, -50%);
}
.img-promo-text {
	color: #ff9800;
	background-color: white;
	width: 75%;
	font-weight: 700;
	position: absolute;
	top: 15px;
	left: -13px;
	font-size: 8px;
	transform: rotate(-45deg);
	border-radius: 8px 8px 0px 0px;
}
.img-takeaway-logo {
	position: absolute;
	top: 5%;
	left: 54px;
	background-color: white;
	height: 16px;
	padding: 2px;
	border-radius: 4px;
}
.list-takeaway-logo {
	position: absolute;
	top: 15%;
	margin-left: 4px;
	background-color: white;
	height: 16px;
	padding: 2px;
	border-radius: 4px;
}

.img-overlay {
	position: absolute;
	top: 9px;
	left: 1px;
}

.img-owned {
	display: flex;
	margin-top: 8px;
	margin-bottom: 2px;
	margin-right: 10px;
	border: 1px solid rgb(234, 234, 234);
	width: 55px;
	height: 55px;
	justify-items: 'center';
	box-sizing: border-box;
	border-radius: 5px;
}

.line-subheader {
	color: white;
	box-shadow: none;
	width: 100%;
	margin-bottom: 0;
	margin-top: 5px;
	border-width: 0;
	height: 2px;
	background-color: white;
}

.MuiDialogContent-root.dialog-content {
	padding: 8px 12px 12px;
}

.orig-padding {
	padding: 8px 12px 12px;
}

.line-description {
	color: #f1f1f1;
	background-color: #f1f1f1;
	height: 2px;
	border-width: 0;
}

.order-button {
	font-size: 40px;
	position: relative;
	bottom: 0px;
}

.order-add {
	color: #ff9800;
	@extend .order-button;
}

.order-minus {
	color: #959595;
	@extend .order-button;
}

.order-quantity {
	font-size: 35px;
}

.custom-label {
	background-color: #e2e2e2;
	color: black;
	width: fit-content;
	padding: 15px 13px 15px 13px;
	border-radius: 3px;
}

.custom-label-checked {
	background-color: #b1aeae;
	color: black;
	width: fit-content;
	padding: 15px 13px 15px 13px;
	border-radius: 3px;
}

.MuiOutlinedInput-input {
	font-size: 14px;
}

.option-radio {
	.MuiFormGroup-root {
		display: inherit;
	}
}

.set-menu-standard {
	padding-bottom: 5px;
}

.weight-input {
	.MuiInputBase-input {
		text-align: end;
		padding: 2px;
	}
}

.text-drop-select {
	// padding: 12px 0px 12px 8px;
	.MuiSelect-outlined.MuiSelect-outlined {
		padding: 12px 0px 12px 8px;
		background-color: #fff;
		font-size: 13px;
		font-weight: 750;
	}
	.header {
		padding: 10px 0px;
		background-color: #fff;
	}
}
.fix-padding {
	.MuiInputBase-root {
		padding-left: 10px;
	}
}
