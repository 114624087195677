.freq-input {
	.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
		padding-top: 12px;
		padding-bottom: 12px;
		font-size: 12px;
		font-weight: 500;
	}
}

.custom-date-business-insight {
	.MuiOutlinedInput-input {
		padding-left: 6px;
		padding-top: 12px;
		padding-bottom: 12px;
		font-size: 12px;
		font-weight: 500;
	}
	.MuiOutlinedInput-adornedEnd {
		padding-right: 10px;
	}
	.MuiIconButton-root {
		padding: 0px;
	}
}
