.accordion-consolidatedEI {
    width: 100%;
    margin-bottom: 4px;
  
    &.MuiAccordionPanel-root {
      border-radius: 3px;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
        0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
      &::before {
        background: none;
      }
      &.Mui-expanded {
        margin: 0px 0px 4px;
      }
    }
    .MuiAccordionSummary-root {
      padding: 0px;
      min-height: 34px;
      width: 100%;
      &.Mui-expanded {
        min-height: unset;
      }
      .MuiAccordionSummary-content {
        margin: 12px;
        .core-list {
          padding-bottom: 0px;
          width: 100%;
          .MuiListItem-root {
            width: 100%;
            box-shadow: unset;
            background: unset;
            margin: unset;
          }
        }
      }
      .MuiButtonBase-root {
        padding: 0px;
        margin-right: 18px;
        // color: #212121;
      }
    }
  
    .MuiAccordionDetails-root {
      background: #ffffff !important;
      padding: 8px;
      flex-wrap: wrap;
      border-top: 1px solid #e6e6e6;
    }
  
    .header-button {
      background-color: #ffffff;
      color: #ff9800;
      font-size: 12px;
      font-weight: 600;
      float: right;
      box-shadow: none;
      border: solid 1px #ff9800;
      margin-right: 4px !important;
      padding: 0px 7px 0px 4px;
      .MuiButton-startIcon {
        margin-right: 4px;
      }
    }
  }
  
  .table tr:nth-child(odd) {
    background-color: #faf2e8;
  }
  
  .table tr:nth-child(even) {
    background-color: #ffffff;
  }
  
  .tableHead {
    font-size: 11px;
    padding: 5px 10px 5px 10px;
    background-color: #ff9800;
  }
  
  .tableContent {
    font-size: 11px;
    padding: 5px 10px 5px 10px;
  }
  
  .advanceSearch {
    width: 49%;
    margin: 0px;
    padding-right: 2px;
  }