.bill-footer {
	background-color: #ffe5b5;
	padding: 10px;
	position: fixed;
	bottom: 50px;
	// bottom: 54px;
	// width: 95%;
	@media (orientation: portrait) {
	}
	// @media (orientation: landscape) {

	// }
}

.bill-ftr-child {
	margin-bottom: 7px;
}

.line-bill {
	color: #fff;
	box-shadow: none;
	width: 100%;
	margin-bottom: 0;
	margin-top: 5px;
	border-width: 0;
	height: 2px;
	// background-color: #fff;
	background-color: #ffe5b5;
}

.receipt-divider {
	color: #fff;
	box-shadow: none;
	width: 100%;
	margin-bottom: 10px;
	margin-top: 5px;
	border-width: 0;
	height: 2px;
	background-color: #000000;
}

.bill-total {
	margin-top: 12px;
}

.right-info {
	float: right;
	// padding-right: 20px;
}

.item-discount-footer {
	background-color: white;
	padding: 10px 0px;
	position: fixed;
	bottom: 0;
	right: 0;
	width: 100%;
}

.qr-container {
	overflow: hidden;

	// @media screen and (max-width: 650px) {
	// 	margin: 0px !important;
	// }

	@media screen and (min-width: 700px) and (max-width: 1130px) {
		margin: 0px 18% !important;
	}
	@media screen and (min-width: 1131px) and (max-width: 1420px) {
		margin: 0px 18% !important;
	}
	@media screen and (min-width: 1420px) {
		margin: 0px 33% !important;
	}
	// @media screen and (min-width: 1300px) {
	// 	margin: 0px 54vh !important;
	// }
}
